import localStore from 'utils/localStore';
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';

import jwtDecode from 'jwt-decode';
import userEvent from '@testing-library/user-event';

export interface IUserData {
    'api-key': string;
    data: {
        quota: { balance: string; size: string; used: string };
        sender: { enabled: boolean; name: string }[];
    };
    email: string;
    firstname: string;
    lastname: string;
    middlename: string;
    org: { 'api-key': string; fullname: string; id: string };
    token: { expiry: string; string: string };
    username: string;
}
const initialState = {
    user: {} as IUserData,
    isAuthenticated: false,
};

const logOutUser = () => {
    localStore.removeItem('user');
    initialState.user = {} as IUserData;
    initialState.isAuthenticated = false;
    // window.location.reload();
};

if (localStore.getItem('user') !== '') {
    const user: IUserData = localStore.getItem('user');
    const expiry = new Date(user.token.expiry).getTime();

    if (expiry < Date.now()) {
        localStore.removeItem('token');
    }
    initialState.user = localStore.getItem('user');
    initialState.isAuthenticated = true;
} else {
    logOutUser();
}

const getInitialState = () => {
    return initialState;
};
export const createUserSlice = createSlice({
    name: 'user',
    initialState: getInitialState(),
    reducers: {
        addUserProfile: (state, action) => {
            const user = action.payload;
            state.user = { ...state.user, ...user };
            // state.user.image = user?.pictureUrl;
            state.isAuthenticated = true;
        },
        updateUserProfile: (state, action) => {
            const payload = action.payload;
            state.user = { ...state.user, ...payload };
            // state.user.image = payload?.pictureUrl;
        },
        logOut: (state) => {
            // logOutUser();
            localStore.removeItem('user');
            state.user = {} as IUserData;
            // state.user.image = user?.pictureUrl;
            state.isAuthenticated = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { addUserProfile, updateUserProfile, logOut } = createUserSlice.actions;

export default createUserSlice.reducer;
