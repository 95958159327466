// Secure local storage
// Encrypted and compressed ;-)
import SecureLS from 'secure-ls';

const config = {
    encodingType: 'des',
    isCompression: true,
    encryptionSecret: 'my-secret-key',
};
const ls = new SecureLS(config);

const localStore = {
    getItem: <T>(item: string): T => ls.get(item),
    setItem: (item: string, data = {}): void => ls.set(item, data),
    removeItem: (item: string): void => ls.remove(item),
    removeAllItems: (): void => ls.removeAll(),
};

export default localStore;
