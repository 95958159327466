import React from 'react';
import { ThemeProvider } from 'styled-components';
import NPHDA_LOGO from './asset/image/logo_NPHCDA.png';

export const pages = {
    NPHCDA: {
        color: '#2A992B',
        icon: NPHDA_LOGO,
    },
};
const merchantID = 'NPHCDA';
const merchantColor = pages[merchantID].color;
const merchantIcon = pages[merchantID].icon;
interface Props {
    children: any;
}
const Theme = (props: Props) => {
    const { children } = props;
    const [theme, setTheme] = React.useState({
        color: merchantColor || '#0d22c7fd',
        icon: merchantIcon,
    });
    React.useEffect(() => {
        const handleGetMerchantTheme = () => {
            setTheme({
                ...theme,
            });
        };
        handleGetMerchantTheme();
    }, []);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

// export default GlobalStyles;
