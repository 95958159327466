import React, { lazy, Suspense } from 'react';

const Home = lazy(() => import('../pages/home'));
const SentMessages = lazy(() => import('../pages/sent-messages'));
const NotFound = lazy(() => import('../pages/not-found'));
const PhoneGroup = lazy(() => import('../pages/phone-group'));
const DynamicMessages = lazy(() => import('../pages/dynamic-messages'));
const ScheduledMessages = lazy(() => import('../pages/scheduled-messages'));
const Drafts = lazy(() => import('../pages/drafts'));
const Login = lazy(() => import('../pages/auth/Login'));
const CreateAccount = lazy(() => import('../pages/auth/CreateAccount'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
export const Routes = [
    {
        id: 'Home',
        path: '/',
        exact: true,
        isProtected: true,
        component: Home,
    },
    {
        id: 'SentMessages',
        path: '/sent-messages',
        exact: true,
        isProtected: true,
        component: SentMessages,
    },

    {
        id: 'PhoneGroup',
        path: '/phone-group',
        exact: true,
        isProtected: true,
        component: PhoneGroup,
    },
    {
        id: 'DynamicMessages',
        path: '/sms-history',
        exact: true,
        isProtected: true,
        component: DynamicMessages,
    },
    {
        id: 'ScheduledMessages',
        path: '/scheduled-sms',
        exact: true,
        isProtected: true,
        component: ScheduledMessages,
    },
    {
        id: 'Drafts',
        path: '/drafts',
        exact: true,
        isProtected: true,
        component: Drafts,
    },
    {
        id: 'Login',
        path: '/login',
        exact: true,
        isProtected: false,
        component: Login,
    },
    {
        id: 'CreateAccount',
        path: '/create-account',
        exact: true,
        isProtected: false,
        component: CreateAccount,
    },
    {
        id: 'ForgotPassword',
        path: '/forgot-password',
        exact: true,
        isProtected: false,
        component: ForgotPassword,
    },
    {
        id: 'NotFound',
        isProtected: false,
        component: NotFound,
    },
];
