import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userReducer from '../slice/userSlice';

// load string from localStarage and convert into an Object
// invalid output must be undefined
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem('token');
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

// const preloadedState = loadFromLocalStorage();

const combinedReducer = combineReducers({
    userState: userReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'user/logOut') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['createPage/updateApplicationWindow', 'createPage/updateCohortDuration'],
                // // Ignore these field paths in all actions
                // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // // Ignore these paths in the state
                // ignoredPaths: ['items.dates'],
            },
        }),
});
// export const store = configureStore({
//     reducer: {
//         createPageState: createPageReducer,
//         pageListState: pageListReducer,
//         settingsState: settingsReducer,
//         userState: userReducer,
//     },
// });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// const rootReducer = (state, action) => {
//     if (action.type === 'signOutUser') {
//         // check for action type
//         state = undefined;
//     }
//     return combinedReducer(state, action);
// };

// const store = configureStore({
//     reducer: rootReducer,
//     middleware,
//     preloadedState,
//     devTools: process.env.NODE_ENV !== 'production',
//     // devTools: false,
// });

// store.subscribe(() => saveToLocalStorage(store.getState()));
// store.subscribe(() =>
//     _.throttle(() => saveToLocalStorage(store.getState()), 1000)
// );

// store.subscribe(
//     _.throttle(() => {
//         store.getState();
//     }, 1000)
// );

// export default store;
