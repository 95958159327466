import { createGlobalStyle } from 'styled-components';

import AvenirNextDemi from './asset/fonts/AvenirNext-DemiBold.woff';
import AvenirNextDemiWoff2 from './asset/fonts/AvenirNext-DemiBold.woff2';
import AvenirNextDemiItalic from './asset/fonts/AvenirNext-DemiBoldItalic.woff';
import AvenirNextDemiItalicWoff2 from './asset/fonts/AvenirNext-DemiBoldItalic.woff2';
import AvenirNext from './asset/fonts/AvenirNext-Medium.woff';
import AvenirNextWoff from './asset/fonts/AvenirNext-Medium.woff2';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Next';
    src: url(${AvenirNextDemi}) format('woff2'),
        url(${AvenirNextDemiWoff2}) format('woff');
    font-weight: 600;
    font-style: medium;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(${AvenirNext}) format('woff2'),
        url(${AvenirNextWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
   src: url(${AvenirNextDemiItalic}) format('woff2'),
        url(${AvenirNextDemiItalicWoff2}) format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
`;
