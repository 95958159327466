import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { GlobalStyles } from './GlobalStyle';
// import { Home } from './pages/home';
import { ToastProvider } from 'react-toast-notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Theme from 'theme';
import { Layout } from 'components/layout';
import { ProtectedRoute, Routes } from 'routes';
import { useAuthContext } from 'redux-service';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            // retry: false,
            // staleTime: 30000,
            // staleTime: 2000,
        },
    },
});

const App: React.FC = () => {
    const { state: userState } = useAuthContext();

    const { isAuthenticated } = userState;

    return (
        <Suspense
            fallback={
                <div>
                    <h1>Loading...</h1>
                </div>
            }
        >
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Theme>
                    <ToastProvider autoDismiss>
                        <GlobalStyles />
                        <Switch>
                            {Routes.map((route) => {
                                const { id, path, exact, component, isProtected } = route;
                                return isProtected ? (
                                    <ProtectedRoute key={id} exact={exact && exact} path={path} component={component} />
                                ) : (
                                    <Route key={id} exact={exact && exact} path={path} component={component} />
                                );
                            })}
                        </Switch>
                    </ToastProvider>
                </Theme>
            </QueryClientProvider>
        </Suspense>
    );
};

export default App;
