import React, { ElementType, FC, useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { useAuthContext } from 'redux-service';

interface Props {
    component: ElementType;
    exact?: boolean;
    path?: string;
    redirect?: string;
}

export const ProtectedRoute: FC<Props> = ({ component: Component, exact, path, ...rest }: Props) => {
    const { state: userState } = useAuthContext();

    const { isAuthenticated } = userState;
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated === true ? (
                    <Component {...props} exact={exact} path={path} {...rest} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};
